<template>
  <div class="flex flex-col gap-1">
    <label
      v-if="inputLabel && item.labelBefore"
      :for="item.macId"
      class="text-xs text-core-outline"
      >{{ inputLabel }}</label
    >
    <input
      :id="item.macId"
      v-model="inputValue"
      :type="inputType"
      :placeholder="inputPlaceholder"
      class="border border-core-bg rounded-lg w-full p-2 outline-none placeholder:text-sm"
      @input="handleSetValue"
    />
    <label
      v-if="inputLabel && !item.labelBefore"
      :for="item.macId"
      class="text-sm text-core-outline"
      >{{ inputLabel }}</label
    >
  </div>
</template>

<script setup>
import { ref, computed, watch, onBeforeMount, onMounted } from 'vue';
import { CONTROL_SUBTYPES } from '@/services/poll/poll.consts';
const phonePattern = /^(\+7|8)?(\d{0,11})$/;

const props = defineProps({
  item: {
    type: Object,
    default() {
      return {};
    },
  },
});

const emit = defineEmits(['input']);

const inputType = computed(() => {
  return CONTROL_SUBTYPES[props.item.subtype] || 'text';
});

const inputPlaceholder = computed(() => {
  if (props.item.question) return `Укажите ${props.item.question}`;
  if (props.item.placeholder) return props.item.placeholder;
  return null;
});

const inputLabel = computed(() => {
  if (props.item.subtype === 'TEL') return 'Формат: +79991115533';
  if (props.item.label) return props.item.label;
  return null;
});

const inputValue = ref('');

watch(inputValue, () => {
  if (props.item.subtype === 'TEL') {
    inputValue.value = inputValue.value.split(' ').join('');
    if (inputValue.value.length < 2) {
      inputValue.value = '+7';
    }
    if (!inputValue.value.match(phonePattern)) {
      inputValue.value = inputValue.value.substring(
        0,
        inputValue.value.length - 1
      );
      handleSetValue();
      return inputValue.value;
    }
  }
});

const handleSetValue = () => {
  emit('input', { macId: props.item.macId, value: inputValue.value });
};

onBeforeMount(() => {
  if (props.item?.pollAnswer) inputValue.value = props.item.pollAnswer;
});

onMounted(() => {
  if (props.item.subtype === 'TEL' && inputValue.value === '')
    inputValue.value = '+7';
});
</script>
